import React from 'react'
import PropTypes from 'prop-types'
import { NetlifyForm, Honeypot } from 'react-netlify-forms'

import { Input, TextArea } from './input'

const ContactForm = ({
  button,
  email,
  message,
  name,
  successMessage,
  // titleForm,
  leadForm
}) => (
  <NetlifyForm name="Form" action="/" honeypotName="bot-field" data-sal="fade">
    {({ handleChange, success, error }) => (
      <>
        {/*{titleForm &&*/}
        {/*  <h3 className="contactForm-title">{titleForm}</h3>*/}
        {/*}*/}
        {leadForm &&
          <p className="contactForm-lead">{leadForm}</p>
        }
        <Honeypot />
        <div className="form-group">
          <Input
            type="text"
            label={name}
            name="name"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <Input
            type="email"
            label={email}
            name="email"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <TextArea
            label={message}
            name="message"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <button
            className="contactForm-btn"
            type="submit"
          >
            {button}
          </button>
        </div>
        {success && <p>{successMessage}</p>}
        {error && <p>An error occurred, please try again...</p>}
      </>
    )}
  </NetlifyForm>
)

export default ContactForm

ContactForm.propTypes = {
  button: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  // titleForm: PropTypes.string.isRequired,
  leadForm: PropTypes.string.isRequired,
}
